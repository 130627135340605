@import './../../variables.scss';
.QtySelector {
    .QtyInput {
        width: 50px;
        font-size: 13px;
        text-align: center;
        border: 1px solid $primary;
        height: 30px;
        font-weight: bold;
    }

    .AddBtn { margin: 0 5px; }
    .RemoveBtn {
        background-color: $danger;
    }
}