@import './../../variables.scss';

.FooterContainer {
    position: absolute;
    bottom: 0;
    width: 100%;
    
    .MainFooterContainer {
        background-color: $secondary;
        color: white;
        padding: 30px 0 10px 0;

        .Number { 
            a { color: white; }
            font-size: 18px; 
        }

        .LogosContainer { margin-bottom: 40px; }

        p { 
            font-size: 14px;
            margin-bottom: 15px; 
        }

        a { 
            color: #ffffff;
        }

        .SmallIcon {
            background-repeat: no-repeat;
            background-size: 25px;
            display: inline-block;
            height: 25px;
            vertical-align: middle;
            width: 25px;

            &.WpIcon { background-image: url('./icons/wp.png'); }
            &.InstIcon { background-image: url('./icons/i2.png'); }
        }        

        .insta { font-size: 16px; }
        .DataFiscalIcon { 
            width: 50px;
            position: absolute;
            bottom: 20px;
            right: 15px;
        }
    }

    .SubfooterContainer {
        background-color: $secondary;
        
        color: $primary;
        font-size: 12px;
        padding: 15px 0;

        .container { 
            border-top: 1px solid #4b4d5a; 
            padding-top: 15px; 
        }
        a {
            color: $primary;
            .husky-logo {
                background-image: url('./icons/husky.png');
                background-repeat: no-repeat;
                background-size: 100px 15px;
                display: inline-block;
                height: 15px;
                margin-left: 5px;
                vertical-align: middle;
                width: 100px;
            }
        }

        .PowerBy {
            text-align: right;
        }
    }
}

@media (max-width: 576px) {
    .MainFooterContainer,
    .SubfooterContainer {
        text-align: center;
        .PowerBy {
            margin-top: 5px;
            text-align: center!important;
        }
    }
}