@import './../../variables.scss';

.ProductsContainer {
    overflow: hidden; 
    
    .FamiliasContainer {
        backface-visibility: hidden;
        display: flex;
        float: left;
        height: 200vh;
        width: 25%;
        will-change: overflow;

        .FamiliaList {
            height: auto;
            overflow: auto;
            width: 100%;
            -webkit-overflow-scrolling: touch;
            -ms-overflow-style: none;
        }
    }

    .ProductListContainer { 
        padding-left: 10px;
        float: left;
        width: 74%;
    }


    .icon {
        background-size: 48px;
        height: 48px;
        width: 48px;
    }
    .sale { background-image: url('./sale.png'); }
    .weight { background-image: url('./weight.png'); }

    .ProductFilterContainer { margin-bottom: 20px; }

    @media (max-width: 993px) {
        display: initial;
        .ProductListContainer,
        .FamiliasContainer { 
            float: none;
            margin: 15px 0;
            height: auto;
            width: 100%; 
        }
        
        .FamiliaList {
            width: 100%;
        }
    }
}
.UltimaModificacion {
    float: right;
    margin-top: 20px;
    margin-left: 10px;
}

.ProductsError { margin-bottom: 30px; }
