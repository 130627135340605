@import './../../variables.scss';

.Home {
    padding-top: 50px;
    .HighlightSection {        
        display: flex;
        margin-bottom: 50px;

        p {
            margin-top: 35px;
            color: $primary;
            font-weight: bold;;
        }
        .HomeIcon {
            margin-right: 10px;
            display: inline-block;
            background-size: 90px;
            width: 90px;
            height: 90px;
        
            &.qualityIcon { background-image: url('./logo-1.png'); }
            &.stockIcon { background-image: url('./logo-2.png'); }
        }   
    }
}


@media (max-width: 780px) {
    .Home {
        .PresentationContainer {
            .MainText {
                margin-bottom: 20px;
                width: 100%;
            }
        }
    }
}