ul.NavList {
    display: table-row;
    font-family: 'Oswald', sans-serif;
    padding: 0;
    margin: 0;

    li { 
        display: table-cell; 
        &:first-child { padding-left: 0; }
        &:last-child { padding-right: 0; }
    }

    .mag-icon {
        background-repeat: no-repeat;    
        
        cursor: pointer;
        display: inline-block;
        vertical-align: top;
        background-size: 21px;
        height: 21px;
        width: 21px;
        background-position-y: 1px;
        &.product {
            background-image: url('./product.png');
        }

        &.cart {
            background-image: url('./cart.png');
        }
    }
}