@import './variables.scss';
@import 'react-redux-toastr/src/styles/index';

* {
    box-sizing: border-box;
    &:before,
    &:after {
        box-sizing: border-box;
    }
}

body {
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    position: relative;
    color: $tertiary;
}

a,
button,
h1,
h2,
h3,
h4,
h5,
h6 {
    color: $primary;

    &.SectionTitle {
        margin: 40px auto;
    }
}

.highlight {
    color: $primary;
    font-weight: 600;
}

.SubTitle {
    color: $primary;
    font-weight: bold;
    padding: 20px 0;
}

.separator {
    height: 4px;
    background-color: $primary;
    margin-bottom: 25px;
    width: 100px;
}

.SectionContainer {
    min-height: 440px;
}

a { color: $tertiary; }

.ErrorMsg {
    color: $danger;
    font-weight: 600;
}

.SectionsPresentation {
    padding: 40px 0;
    margin-bottom: 40px;
    h3 { 
        color: $primary;
        text-align: center; 
        margin: 20px 0;
    }
}

.main-container {
    min-height: 100vh;
    overflow: hidden;
    display: block;
    position: relative;
    padding-bottom: 400px;
}

.AuthFormContainer {
    width: 50%;
    p { margin-top: 25px; }
    a {
        color: $primary;
        font-weight: 600;
    }
}

.icon {
    background-repeat: no-repeat;
    background-size: 45px 35px;
    display: inline-block;
    height: 35px;
    width: 45px;
    vertical-align: middle;
}

@media (max-width: 500px) {
    .main-container { padding-bottom: 250px; }
   // .content-container { margin: 25px; }
}