@import './../../variables.scss';

.CommonNavContainer {
    padding: 15px;
    overflow: hidden;

    .LogoContainer { float: left; }
    .NavContainer { float: right; }

    .CommonNavLinks {
        li {
            padding: 20px 10px;

            a.active-route,
            a:hover {
                color: $primary;
                text-decoration: none;
            }
        }
    }
}

@media (max-width: 475px) {
    .CommonNavContainer {
        overflow: initial;
        text-align: center;
        .NavContainer,
        .LogoContainer { float: none; }

        .NavList {
            display: initial;
            li {
                display: initial;
                padding: 20px;
            }
        }
    }
}