@import './../../variables.scss';
.PedidoItem {
    padding: 20px 0;
    border-bottom: 20px solid $secondary-light;
    width: 70%;
    margin: 20px 0;    

    .DateLabel,
    .DateValue,
    .SuCompra { 
        color: $primary; 
        font-family: 'Oswald', sans-serif;
    }

    .DateLabel {
        color: $tertiary;
    }

    .DateLabel { 
        font-size: 14px;
        margin-bottom: 0;
    }
    .DateValue {
        font-size: 26px;
        font-weight: 500;
    }

    ul {
        list-style: disc;
        padding-left: 15px;
        color: black;
        font-weight: 600;
    }

    .TotalPedido {
        font-family: 'Oswald', sans-serif;
        color: $primary;
        text-align: right;
        span { font-weight: 600; }
    }
}
@media (max-width: 993px) {
    .PedidoItem { width: 100%; }
}