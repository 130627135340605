@import './../../variables.scss';
.ProductList {
    .pagination {
        margin: 30px auto;

        .ActivePage,
        .PageButton {
            border-radius: 100px;
            width: 50px;
            height: 50px;
            color: $primary;
            background-color: white;
            margin-right: 5px;

            &.nextPage {
                background-color: $tertiary;
                color: white;
                width: 125px;
            }

            &:last-child { margin-right: 0; }

            &:focus {
                box-shadow: none;
                outline: none;
            }

            &:hover {
                color: white;
                background-color: $primary;
            }
        }

        .ActivePage {
            color: white;
            background-color: $primary;
        }
    }
}

.ProductGetStarted {
    margin: 100px auto;
    font-size: 21px;
    text-align: center;
    width: 300px;

    .separator { margin: auto; }
}
