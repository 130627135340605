@import './../../variables.scss';

.ProductItem {
    border: 2px solid #eaeaea;
    margin-bottom: 30px;

    .ImgContainer {
        background-color: white;
        text-align: center;
        min-height: 175px;
        max-height: 175px;

        img { 
            height: 175px; 
            &.notAvailable { width: 100%; }
        }
    }

    .ContentContainer {
        background-color: $tertiary-light;
        padding: 15px;

        .loggedProduct { 
            min-height: 185px;
        }

        .Desart {
            color: $tertiary;
            font-size: 14px;
        }

        .nota {
            margin-top: 20px;
            font-size: 12px;
        }
    
        .Codart {
            color: $primary;
            font-weight: 900;
            font-size: 12px;
            margin-bottom: 5px;
        }

        .Unidad {
            color: #1f3d62;
            font-weight: 900;
            font-size: 12px;
            margin-top: 5px;
        }
        
        .Categorias { 
            color: $primary; 
            font-weight: 600;
            font-size: 11px;
            margin-bottom: 10px;
        }

        .Desart, .Categorias {
            min-height: 30px;
            max-height: 30px;
        }

        .Price {
            font-size: 20px;
            font-family: 'Oswald', sans-serif;
            font-weight: 500;
            color: $tertiary;
            margin-bottom: 5px;
        }
    }
}


@media (max-width: 768px) {
    .ProductItem .ImgContainer img.notAvailable { width: auto; }
}

@media (max-width: 600px) {
    .ProductItem {
        .ContentContainer {
            min-height: auto;
            .Desart, .Categorias {
                min-height: auto;
                max-height: auto;
            }
        }
    }
}
