@import './../../variables.scss';

.WpBubble {
    position: fixed;
    right: 0;
    padding: 10px 5px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    bottom: 150px;
    z-index: 9999;
    background-color: #395677;

    p { 
        color: white;
        margin-bottom: 5px;
        font-family: 'Oswald', sans-serif;
        font-size: 12px;
    }
    .WpIcon {
        background-image: url('./wp.png');
        background-repeat: no-repeat;        
        background-size: 48px;
        cursor: pointer;
        display: inline-block;
        height: 48px;
        width: 48px;
        vertical-align: middle;
        margin-left: 5px;
    }
}